import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const AUTH = process.env.REACT_APP_API_AUTH_KEY;


const api = axios.create({
  baseURL: BASE_URL,
});

// Function to set the Authorization token
const setAuthToken = (token) => {
  if (token) {
    // Store token in localStorage
    localStorage.setItem("jwtToken", token);
    // Set token in the Axios instance headers
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    // Remove token from localStorage
    localStorage.removeItem("jwtToken");
    // Remove token from Axios instance headers
    delete api.defaults.headers.common["Authorization"];
  }
};

// Retrieve token from localStorage and set it in Axios instance headers if it exists
const token = localStorage.getItem("jwtToken");
if (token) {
  setAuthToken(token);
}

export { api, setAuthToken };

export const createClient = (clientData) => api.post('v1/client/create', clientData);

export const createAssistant = (assistantData) => {
  console.log('Sending assistant data to API:', assistantData);
  return api.post('v1/assistant/create', assistantData, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const startChat = () => api.get('v1/chat/start');
export const sendChatMessage = (messageData) => api.post('v1/chat/', messageData);
export const setFirebaseUser = (userData) => api.post('v1/firestore/set_fb_user', userData);

export const createScrapingSettings = (settingsData, assistantId) => {
  return api.post('v1/firestore/create_scraping', settingsData, {
    params: { assistant_id: assistantId }
  });
};

export const generateDocumentation = (companyUrl) => api.post(`v1/firecrawl/generate_documentation?company_url=${companyUrl}`);

export const getAllConversations = () => api.get('v1/firestore/get_all_conversations');
export const getLeads = (clientId) => api.get(`v1/firestore/get_leads?client_id=${clientId}`);
export const triggerCaptureLead = (leadEmail, leadName, assistantId, threadId, authorization) => api.post(`v1/tools/capture_lead?lead_email=${leadEmail}&lead_name=${leadName}&assistant_id=${assistantId}&thread_id=${threadId}&authorization=${authorization}`);
