import React from "react";
import "./Conversation.css"; // Ensure your CSS file is styling this component properly

export default function ConversationPreview({ preview, clients, onSelect, isSelected }) {
  const cleanTimestamp = new Date(preview.timestamp).toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  });

  // Find the company name matching the client_id
  const company_name = clients.reduce((acc, element) => {
    return element.client_id === preview.client_id ? element.company_name : acc;
  }, "N/A");

  return (
    <div
      className={`conversation-preview-button ${isSelected ? 'selected' : ''}`}
      onClick={onSelect}
      style={{
        padding: '1em 2em',
        margin: '0.5em 0',
        width: '85%',
        textAlign: 'left',
        backgroundColor: isSelected ? '#bfbfbf' : 'white',
        cursor: 'pointer',
        borderRadius: '8px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
      }}
    >
      <div className="conversation-preview">
        <h3>{cleanTimestamp}</h3>
        <div className="conversation-preview-infos">
          <p>Company: {company_name}<br/></p>
          <p className="conversation-preview-message">{preview.messages[0].value}</p>
        </div>
      </div>
    </div>
  );
}