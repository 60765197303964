import React, { useEffect, useState, useRef } from "react";
import ConversationItem from "./ConversationItem";
import { getAllConversations, api } from "../services/api";
import ConversationPreview from "./ConversationPreview";

export default function Conversations() {
  const [conversations, setConversations] = useState([]);
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [conversationsLoading, setConversationsLoading] = useState(true);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const selectedConversationRef = useRef(null);
  const [filters, setFilters] = useState({
    companyName: '',
    startDate: '', // Start date for filtering
    endDate: ''    // End date for filtering
  });

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await getAllConversations();
        setConversations(response.data);
        setFilteredConversations(response.data);  // Start with all conversations
      } catch (err) {
        setErrors(prevErrors => [...prevErrors, 'Error fetching conversations']);
      } finally {
        setConversationsLoading(false);
      }
    };

    const fetchClients = async () => {
      try {
        const response = await api.get(`v1/client/retrieve_all`);
        setClients(response.data.data);
      } catch (err) {
        setErrors(prevErrors => [...prevErrors, 'Error fetching clients']);
      } finally {
        setClientsLoading(false);
      }
    };

    fetchConversations();
    fetchClients();
  }, []);

  useEffect(() => {
    const matchingClientIds = clients
      .filter(client => 
        client.company_name.toLowerCase().includes(filters.companyName.toLowerCase())
      )
      .map(client => client.client_id);

    // Check if a timestamp falls within the date range
    const isWithinDateRange = (timestamp) => {
      const conversationDate = new Date(timestamp).toISOString().split('T')[0];
      const { startDate, endDate } = filters;
      if (startDate && endDate) {
        return conversationDate >= startDate && conversationDate <= endDate;
      }
      return true;  // If no dates are set, return true
    };

    // Apply filtering based on client_id and date range
    const results = conversations.filter(conversation => {
      const matchesClient = !filters.companyName || matchingClientIds.includes(conversation.client_id);
      const matchesDate = isWithinDateRange(conversation.timestamp);

      return matchesClient && matchesDate;
    });

    setFilteredConversations(results);
  }, [filters, conversations, clients]);

  const handleSelectedConvo = (id) => {
    const conv = conversations.find((c) => c.thread_id === id);
    setSelectedConversation(conv);

    if (selectedConversationRef.current) {
      selectedConversationRef.current.scrollTop = 0;
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  if (conversationsLoading || clientsLoading) return <div className="loading">Loading...</div>;
  if (errors.length > 0) return <div className="error">{errors.join('. ')}</div>;

  // Sort the filtered conversations by timestamp
  const sortedConversations = [...filteredConversations].sort((a, b) => {
    return new Date(b.timestamp) - new Date(a.timestamp);
  });

  return (
    <div className="conversations" style={{ paddingTop: '80px' }}>
      <div className="conversation-container">
        <div className="conversation-list">
          <div className="filters">
            <input
              type="text"
              name="companyName"
              placeholder="Filter by company name"
              value={filters.companyName}
              onChange={handleFilterChange}
            />
            <input
              type="date"
              name="startDate"
              placeholder="Start Date"
              value={filters.startDate}
              onChange={handleFilterChange}
            />
            <input
              type="date"
              name="endDate"
              placeholder="End Date"
              value={filters.endDate}
              onChange={handleFilterChange}
            />
          </div>
          {sortedConversations.map((conversation) => (
            <ConversationPreview
              key={conversation.thread_id}
              preview={conversation}
              clients={clients}
              onSelect={() => handleSelectedConvo(conversation.thread_id)}
              isSelected={selectedConversation && selectedConversation.thread_id === conversation.thread_id}
            />
          ))}
        </div>
        <div className="selected-conversation-container" ref={selectedConversationRef}>
          {selectedConversation && (
            <div>
              <ConversationItem displayed={selectedConversation} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}