import React, { useState, useEffect } from 'react';
import './CaptureCreation.css';
import { createClient, createAssistant, setFirebaseUser, createScrapingSettings, generateDocumentation } from '../services/api';

const modelOptions = [
    "gpt-3.5-turbo-1106",
    "gpt-3.5-turbo",
    "gpt-4",
    "gpt-4-1106-preview",
    "gpt-4-turbo-2024-04-09",
    "gpt-4o",
    "gpt-4o-2024-05-13",
    "claude-3-haiku-20240307",
    "claude-3-sonnet-20240229",
    "claude-3-5-sonnet-20240620",
];

const CaptureCreation = () => {
  const [formData, setFormData] = useState({
    company_name: '',
    url: '',
    email: '',
    status: 'demo',
    crm: 'none',
    client_id: '',
    model: 'gpt-4-turbo-2024-04-09',
    instructions: '',
    documentation: {},
    product: 'capture',
    messenger_id: '',
    assistant_name: '',
    vf_id: '',
    first_name: '',
    last_name: '',
    settings: {
      company_name: '',
      height: '80%',
      use_lang_tag: true,
      mobile_popup: false,
      popup_once: false,
      chatbot_names: {
        english: '',
        french: ''
      },
      slogans: {
        english: '',
        french: ''
      },
      introductions: {
        english: '',
        french: ''
      },
      urls: [
        { url: '', language: 'english', init_question: '', popup_one: '', popup_two: '' },
        { url: '', language: 'french', init_question: '', popup_one: '', popup_two: '' }
      ]
    }
  });
  const [useExistingClient, setUseExistingClient] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const generateAssistantName = (companyName) => `${companyName.toLowerCase().replace(/\s+/g, '-')}-capture`;
  const generateAssistantDescription = (companyName) => `Capture for ${companyName}`;

  useEffect(() => {
    if (!useExistingClient && formData.company_name) {
      setFormData(prev => ({
        ...prev,
        assistant_name: generateAssistantName(prev.company_name)
      }));
    }
  }, [formData.company_name, useExistingClient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  const handleNestedChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => {
      const newState = { ...prevState };
      const nameParts = name.split('.');
      let current = newState;
      for (let i = 0; i < nameParts.length - 1; i++) {
        if (nameParts[i].includes('[')) {
          const [arrayName, indexStr] = nameParts[i].split('[');
          const index = parseInt(indexStr);
          current = current[arrayName][index];
        } else {
          current = current[nameParts[i]];
        }
      }
      current[nameParts[nameParts.length - 1]] = type === 'checkbox' ? checked : value;
      return newState;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    try {
      let clientId = formData.client_id;
      let assistantName = formData.assistant_name;

      if (!useExistingClient) {
        const clientResponse = await createClient({
          document_id: "",
          company_name: formData.company_name,
          url: formData.url,
          email: formData.email,
          status: "demo",
          crm: "none"
        });
        clientId = clientResponse.data.data.client_id;
        setSuccess('Client created successfully!');
        assistantName = generateAssistantName(formData.company_name);

        const firebaseUserData = {
          uid: '',
          client_id: clientId,
          vf_id: formData.vf_id,
          company_name: formData.company_name,
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          payment_date: "2024-08-01",
          payment_amount: 0
        };
        await setFirebaseUser(firebaseUserData);
        setSuccess(prev => `${prev} Firebase User created successfully!`);
      }

    const currentTimestamp = new Date().toISOString();

    console.log("Generating Documentation ...")
    const documentation = (await generateDocumentation(formData.url)).data.data;
    setSuccess(prev => `${prev} Documentation generated successfully!`);
    console.log('Documentation:', documentation);

    const assistantData = {
      client_id: clientId,
      model: formData.model,
      name: assistantName,
      description: generateAssistantDescription(formData.company_name),
      instructions: "",
      file_ids: [],
      tools: [],
      product: "capture",
      created_at: currentTimestamp,
      last_modified: currentTimestamp,
      messenger_id: formData.messenger_id || "none",
      twilio_number: "none",
      prompt: {
        "instructions": formData.instructions,
        "documentation": documentation,
        "examples": []
      },
      vf_id: formData.vf_id,
    };
    console.log('Sending assistant data:', assistantData);

    const assistantResponse = await createAssistant(assistantData);
    console.log('Assistant response:', assistantResponse.data);
    let assistantId;
    assistantId = assistantResponse.data.data[0].assistant_id; // Store the assistant_id
    console.log('Assistant ID:', assistantId);

    setSuccess(prev => `${prev} Assistant created successfully!`);
    console.log('Assistant created:', assistantResponse.data);
  
    const scrapingSettingsData = {
      ...formData.settings,
      company_name: formData.company_name
    };

    await createScrapingSettings(scrapingSettingsData, assistantId);
    setSuccess(prev => `${prev} Scraping settings created successfully!`);


  } catch (err) {
    console.error('Full error object:', err);
    setError(`Error creating capture: ${err.message}`);
    if (err.response) {
      console.error('Response data:', err.response.data);
      console.error('Response status:', err.response.status);
      console.error('Response headers:', err.response.headers);
      setError(prev => `${prev}\nServer response: ${JSON.stringify(err.response.data)}`);
    }
  }
};

  return (
    <div className="capture-creation">
      <h1>Create Capture</h1>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group checkbox-group">
          <input
            type="checkbox"
            id="useExistingClient"
            checked={useExistingClient}
            onChange={() => setUseExistingClient(!useExistingClient)}
          />
          <label htmlFor="useExistingClient">Use existing client</label>
        </div>
        {useExistingClient ? (
          <>
            <div className="form-group">
              <label htmlFor="client_id">Client ID</label>
              <input
                id="client_id"
                name="client_id"
                value={formData.client_id}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="company_name">Company Name</label>
              <input
                id="company_name"
                name="company_name"
                value={formData.company_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="url">URL</label>
              <input
                id="url"
                name="url"
                type="url"
                value={formData.url}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="assistant_name">Assistant Name</label>
              <input
                id="assistant_name"
                name="assistant_name"
                value={formData.assistant_name}
                onChange={handleChange}
                required
              />
            </div>
          </>
        ) : (
          <>
            <div className="form-group">
              <label htmlFor="company_name">Company Name</label>
              <input
                id="company_name"
                name="company_name"
                value={formData.company_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="url">URL</label>
              <input
                id="url"
                name="url"
                type="url"
                value={formData.url}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="vf_id">VF ID</label>
              <input
                id="vf_id"
                name="vf_id"
                value={formData.vf_id}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="first_name">First Name</label>
              <input
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="last_name">Last Name</label>
              <input
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </div>
            {formData.company_name && (
              <div className="form-group">
                <label>Generated Assistant Name</label>
                <p>{formData.assistant_name}</p>
              </div>
            )}
          </>
        )}
        <div className="form-group">
          <label htmlFor="model">Model</label>
          <select
            id="model"
            name="model"
            value={formData.model}
            onChange={handleChange}
            required
          >
            {modelOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="instructions">Instructions</label>
          <textarea
            id="instructions"
            name="instructions"
            value={formData.instructions}
            onChange={handleChange}
            rows="10"
          />
        </div>
        <h2>Settings</h2>

        <div className="form-group">
          <label htmlFor="settings_height">Height</label>
          <input
            id="settings_height"
            name="settings.height"
            value={formData.settings.height}
            onChange={handleNestedChange}
            placeholder="80%"
          />
        </div>

        <div className="form-group">
          <p><strong>Use Language Tag</strong></p>
          <label>
            <input
              type="checkbox"
              name="settings.use_lang_tag"
              checked={formData.settings.use_lang_tag}
              onChange={handleNestedChange}
            />
          </label>
        </div>

        <div className="form-group">
          <p><strong>Mobile Pop-up</strong></p>
          <label>
            <input
              type="checkbox"
              name="settings.mobile_popup"
              checked={formData.settings.mobile_popup}
              onChange={handleNestedChange}
            />
          </label>
        </div>

        <div className="form-group">
          <p><strong>Pop-up Once</strong></p>
          <label>
            <input
              type="checkbox"
              name="settings.popup_once"
              checked={formData.settings.popup_once}
              onChange={handleNestedChange}
            />
          </label>
        </div>

        {['english', 'french'].map(lang => (
          <div key={lang}>
            <h3>{lang.charAt(0).toUpperCase() + lang.slice(1)}</h3>
            <div className="form-group">
              <label htmlFor={`chatbot_name_${lang}`}>Chatbot Name</label>
              <input
                id={`chatbot_name_${lang}`}
                name={`settings.chatbot_names.${lang}`}
                value={formData.settings.chatbot_names[lang]}
                onChange={handleNestedChange}
                placeholder={`Chatbot name in ${lang}`}
              />
            </div>
            <div className="form-group">
              <label htmlFor={`slogan_${lang}`}>Slogan</label>
              <input
                id={`slogan_${lang}`}
                name={`settings.slogans.${lang}`}
                value={formData.settings.slogans[lang]}
                onChange={handleNestedChange}
                placeholder={`Slogan in ${lang}`}
              />
            </div>
            <div className="form-group">
              <label htmlFor={`introduction_${lang}`}>Introduction</label>
              <textarea
                id={`introduction_${lang}`}
                name={`settings.introductions.${lang}`}
                value={formData.settings.introductions[lang]}
                onChange={handleNestedChange}
                placeholder={`Introduction in ${lang}`}
              />
            </div>
          </div>
        ))}
      {formData.settings.urls.map((url, index) => (
        <div key={index}>
          <h3>URL {index + 1}</h3>
          <div className="form-group">
            <label htmlFor={`url_${index}`}>URL</label>
            <input
              id={`url_${index}`}
              name={`settings.urls[${index}].url`}
              value={url.url}
              onChange={handleNestedChange}
              placeholder="https://example.com"
            />
          </div>
          <div className="form-group">
            <label htmlFor={`language_${index}`}>Language</label>
            <select
              id={`language_${index}`}
              name={`settings.urls[${index}].language`}
              value={url.language}
              onChange={handleNestedChange}
            >
              <option value="english">English</option>
              <option value="french">French</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor={`init_question_${index}`}>Initial Question</label>
            <input
              id={`init_question_${index}`}
              name={`settings.urls[${index}].init_question`}
              value={url.init_question}
              onChange={handleNestedChange}
              placeholder="Initial question for this URL"
            />
          </div>
          <div className="form-group">
            <label htmlFor={`popup_one_${index}`}>Popup One</label>
            <input
              id={`popup_one_${index}`}
              name={`settings.urls[${index}].popup_one`}
              value={url.popup_one}
              onChange={handleNestedChange}
              placeholder="First popup message"
            />
          </div>
          <div className="form-group">
            <label htmlFor={`popup_two_${index}`}>Popup Two</label>
            <input
              id={`popup_two_${index}`}
              name={`settings.urls[${index}].popup_two`}
              value={url.popup_two}
              onChange={handleNestedChange}
              placeholder="Second popup message"
            />
          </div>
        </div>
      ))}

        <button type="submit">Create Capture</button>
      </form>
    </div>
  );
};

export default CaptureCreation;