// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    overflow: hidden; /* Prevent double scroll */
}

.capture-creation {
    overflow-y: auto;   
    max-height: calc(100vh - 100px); /* Slightly reduce the max-height */
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box; /* Make sure padding is included in the total max-height */
    padding-bottom: 40px; /* Add padding at the bottom to allow comfortable scrolling */
}`, "",{"version":3,"sources":["webpack://./src/components/CaptureCreation.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB,EAAE,0BAA0B;AAChD;;AAEA;IACI,gBAAgB;IAChB,+BAA+B,EAAE,mCAAmC;IACpE,cAAc;IACd,aAAa;IACb,sBAAsB,EAAE,0DAA0D;IAClF,oBAAoB,EAAE,6DAA6D;AACvF","sourcesContent":["body, html {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    margin: 0;\n    overflow: hidden; /* Prevent double scroll */\n}\n\n.capture-creation {\n    overflow-y: auto;   \n    max-height: calc(100vh - 100px); /* Slightly reduce the max-height */\n    margin: 0 auto;\n    padding: 20px;\n    box-sizing: border-box; /* Make sure padding is included in the total max-height */\n    padding-bottom: 40px; /* Add padding at the bottom to allow comfortable scrolling */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
