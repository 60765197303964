import React from "react";
import ReactMarkdown from 'react-markdown';
import "./Conversation.css";

export default function MessageItem({ bubble }) {
  const isAssistant = bubble.role === "assistant";
  const messageClass = isAssistant ? "chatbot-message" : "user-message";
  const messageContainerClass = isAssistant ? "chatbot-container" : "user-container";
  const roleDisplay = isAssistant ? "Assistant" : "User";
  //convert from int date
  const date = new Date(bubble.created_at * 1000);
  const formattedDate = date.toLocaleString();

  return (
    <div className="message-container">
      {isAssistant ? (
        <img
          className="assitant-image"
          src="https://storage.googleapis.com/capture-assets/QBG_Logo_Icone.jpg"
          alt="Assistant"
        />
      ) : null}
      <div className={messageContainerClass}>
        <div className={messageClass}>
          <p>{roleDisplay}</p>
          <ReactMarkdown>{bubble.value}</ReactMarkdown>
        </div>
        <p className="timestamp">{formattedDate}</p>
      </div>
    </div>
  );
}
